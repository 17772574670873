<template>
  <section class="section flex tablestat">
    <div class="section-header f fs">
      <h2>{{ $t('homepage.markets_stats') }}</h2>
      <svg class="wh18 ml10">
        <use xlink:href="/icons/sprite.svg#i_stat" />
      </svg>
    </div>
    <div class="section-body">
      <div class="tablestat_wrap">
        <div class="table">
          <div class="thead">
            <div class="tr">
              <div class="th position stickyTop">
                <div class="wh40">#</div>
              </div>
              <div class="th name stickyTop">
                <div class="w240">{{ $t('name') }}</div>
              </div>
              <div class="th count stickyTop">
                <div class="w150">{{ $t('items_sold_today') }}</div>
              </div>
              <div class="th count stickyTop">
                <div class="w150">{{ $t('items_sold_today_valued') }}</div>
              </div>
              <div class="th count stickyTop">
                <div class="w150">{{ $t('7d_sales') }}</div>
              </div>
              <div class="th stickyTop">
                <div class="w150">{{ $t('chart') }}</div>
              </div>
              <div class="th actions stickyTop">
                <div class="w100">{{ $t('actions') }}</div>
              </div>
            </div>
          </div>
          <div class="tbody">
            <div v-if="loadingMarkets">
              <div v-for="p in placeholder" :key="p" class="p">
                <div class="p-id wh40 f">
                  <div class="p_text wh20"></div>
                </div>
                <div class="p_block p-market w240"></div>
                <div class="p-listed w150">
                  <div class="p_text w100"></div>
                </div>
                <div class="p-value w150">
                  <div class="p_text w140"></div>
                </div>
                <div class="p-sales w150">
                  <div class="p_text w140"></div>
                </div>
                <div class="p-chart w150">
                  <div class="p_text w150"></div>
                </div>
                <div class="p_block p-action w100"></div>
              </div>
            </div>
            <template v-else>
              <LazyPagesTheMainComponentsMainTableMarketRow
                  v-for="(market, index) in marketsStats"
                  :key="market.market_full_name"
                  :index="+index"
                  :market="market"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {useResize} from '~/composables/resize'
import {useStore} from '~/store'
import {getMarketsStats} from "~/utils/api";
import EventBus from "~/composables/bus";

const currW = useResize()

// use store
const store = useStore()

const currencyName = computed(() => store.currency)
const currency = computed(() => store.rate)

const loadingMarkets = ref(true)
const placeholder = ref(5)

onMounted(() => {
  getMarkets()
})

const marketsStats = computed(() => store.markets_stats)

async function getMarkets() {
  try {
    const {data, errors} = await getMarketsStats()

    if (errors?.length) {
      throw new Error(errors[0]?.message)
    }

    store.markets_stats = data.marketsMainStats.filter(item => item.market_name !== 'csgoempire')
  } catch (e: Error | any) {
    console.log('Get markets error: ', e)
    EventBus.$emit('notify', {
      success: false,
      msg: e.message || 'An error occurred'
    })
  } finally {
    loadingMarkets.value = false
  }
}
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;
@use 'sass:color';

.tablestat {
  //.btn_close {
  //  display: none;
  //}

  &_wrap {
    background: $dark;
    border-radius: 0.6rem;
    padding: 0 2.4rem;
  }

  .table {
    width: 100%;
    border-collapse: collapse;

    .thead {
      .tr {
        border-top: none;
      }
    }

    .th {
      position: sticky;
      z-index: 1;
      top: 0px;
      min-height: 50px;
      color: $light;
      font-weight: 500;
      text-align: left;
      padding: 1rem;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        //width: 100%;
      }
    }

    .td, &:deep(.td) {
      text-align: left;
      // background: darken($black, 1.5%);
      border-top: 1px solid $grayDarken;
      padding: 10px;
      font-weight: 500;
      transition: background 0.3s;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;

      & > div {
        display: flex;
        align-items: center;
      }
    }


    .tr, &:deep(.tr) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tal {
      text-align: left;
      justify-content: flex-start;
    }

    .wh20 {
      min-width: 2rem;
      width: 2rem;
    }
  }

  .tablestat .section-body {
    min-height: 22.2rem;
    padding-bottom: 5rem;
  }

  .p {
    width: 100%;
    background: $dark;
    height: 6.1rem;
    border-top: 1px solid $grayDarken;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_block {
      background: color.adjust($dark, $lightness: 3%);
      border-radius: 0.6rem;
      height: 4rem;
      margin: 0 1rem;
      background-image: linear-gradient(
              to right,
              color.adjust($dark, $lightness: 3%) 0%,
              color.adjust($dark, $lightness: 3%) 50%,
              color.adjust($dark, $lightness: 5%) 70%,
              color.adjust($dark, $lightness: 3%) 100%
      );
      background-size: 200% 100%;
      animation: bgPos 1s linear infinite;
    }

    &_text {
      background: color.adjust($dark, $lightness: 3%);
      border-radius: 0.6rem;
      height: 2rem;
      background-image: linear-gradient(
              to right,
              color.adjust($dark, $lightness: 3%) 0%,
              color.adjust($dark, $lightness: 3%) 50%,
              color.adjust($dark, $lightness: 5%) 70%,
              color.adjust($dark, $lightness: 3%) 100%
      );
      background-size: 200% 100%;
      animation: bgPos 1s linear infinite;
    }

    .w140 {
      width: 14rem;
      min-width: 14rem;
    }
  }

  @keyframes bgPos {
    0% {
      background-position: -50% 0;
    }

    100% {
      background-position: -250% 0;
    }
  }

  @media screen and (max-width: 1200px) {
    .tablestat {
      .position {
        display: none;
      }

      .name, &:deep(.name) {
        flex-grow: 1;
        justify-content: flex-start;
      }

      .w150 {
        display: block !important;
      }

      .section-body {
        min-height: initial;
        padding-bottom: 5rem;
      }

      .td, &:deep(.td) {
        .ml10 {
          margin-left: 0;
        }
      }

      .count &:deep(.count) {
        .w150 {
          width: 10rem;
          min-width: 10rem;
        }
      }

      &_wrap {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
  }

  @media screen and (max-width: 979px) {
    .tablestat {
      &_wrap {
        padding: 0 1rem;
      }

      .table {
        .td, &:deep(.td),
        .th {
          padding: 10px 5px;
        }

        .name, &:deep(.name) {
          // order: -2;
          .w240 {
            width: 15rem;
            min-width: 15rem;
          }

          a {
            padding-right: 1.6rem;
          }

          .cselect {
            display: none;
          }
        }

        .actions {
          order: -1;
        }

        .w100 {
          width: 6rem;
          min-width: 6rem;

          .btn {
            padding: 0.5rem;
            width: 4rem;
            margin: auto;

            i {
              font-size: 1.4rem;
            }

            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
