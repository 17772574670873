export function getImageFromProxy(url: string, sizes: string = '400:350', replaceSize: boolean = false) {
    if (!url) return '';
    if (url.includes('imageproxy')) return replaceSize ? url?.replace(/rs:fit:\d+:\d+:/, `rs:fit:${sizes}:`) : url;

    if (url.includes('/images/kukri_knife.png')) {
        return url
    }
    return `https://imageproxy.csmarketcap.com/insecure/rs:fit:${sizes}:0/g:nowe/f:webp/plain/${url}`
}

export function getImageFromWaxpeer(name: string, sizes: string = '400:350') {
    name = getModifiedString(name)
    if (name.includes('doppler') &&
        !name.includes('phase') &&
        !name.includes('emerald') &&
        !name.includes('ruby') &&
        !name.includes('black-pearl') &&
        !name.includes('sapphire')
    ) {
        name = name.split("doppler").join(`doppler-phase-1`)
    }

    if (name === 'kukri-knife') {
        return `/images/kukri_knife.png`;
    }

    return getImageFromProxy(
        `https://images.csmarketcap.com/i/730-${name}.webp`,
        sizes
    )
}


let imagesUrl = '';
const imageCache = new Map<string, string>();

export function getMarketImage(marketLogo?: string): string {
    if (!marketLogo) return '';

    if (imageCache.has(marketLogo)) {
        return imageCache.get(marketLogo) as string;
    }

    if (!imagesUrl) {
        const config = useRuntimeConfig();
        imagesUrl = config.public.imagesUrl;
    }

    const imageUrl = `${imagesUrl}${marketLogo}`;
    imageCache.set(marketLogo, imageUrl);

    return imageUrl;
}

export function getTournamentImage(name?: string, sizes: string = '400:350') {
    if (!name) return '';
    if (name.includes('imageproxy')) return name;

    return `https://imageproxy.csmarketcap.com/insecure/rs:fit:${sizes}:0/g:nowe/f:webp/plain/https://images.csmarketcap.com/i/tournament-${name.toLowerCase().replaceAll(' ', '_')}.webp`
}
