import {defineNuxtPlugin} from '#app'
import Intercom from '@intercom/messenger-js-sdk'
import {useStore} from "~/store";

export default defineNuxtPlugin(() => {
    const store = useStore()
    if (store.user) {
        Intercom({
            app_id: 'jijl2xhy',
            avatar: store.user.avatar_url,
            user_id: store.user.steamid64,
            name: store.user.persona_name,

            // alignment: 'left',         // Левое или правое положение на странице
            horizontal_padding: 150,    // Горизонтальный отступ
            vertical_padding: 55       // Вертикальный отступ

        })
    } else {
        Intercom({
            app_id: 'jijl2xhy',
            // alignment: 'left',         // Левое или правое положение на странице
            horizontal_padding: 150,    // Горизонтальный отступ
            vertical_padding: 55       // Вертикальный отступ

        })
    }
})
