<template>
  <footer id="footer" class="footer">
    <div
        :class="{
          'comparison': route.name?.toString().includes('comparison'),
          'search-page': route.name?.toString().includes('search')
        }"
        class="footer_wrap container"
    >
      <div class="lside">
        <Logo class="logo" />

        <div class="f gap10 mta">
          <a aria-label="VK page" class="messenger-link" href="https://vk.com/csmarketcap"
             target="_blank">
            <svg height="24" width="24">
              <use xlink:href="/icons/sprite.svg#i-vk"></use>
            </svg>
          </a>
          <a aria-label="Telegram" class="messenger-link" href="https://t.me/csmarketcap"
             target="_blank">
            <svg height="28" width="28">
              <use xlink:href="/icons/sprite.svg#i-telegram"></use>
            </svg>
          </a>
          <span class="copyright hm mt0">
            {{ `${new Date().getFullYear()} © CSMARKETCAP.COM` }}
          </span>
        </div>
      </div>

      <div class="rside">
        <FooterComponentsMenu />
      </div>
      <!--      <div class="f gap8 mta ht hd">-->
      <!--        <a aria-label="VK page" class="messenger-link" href="https://vk.com/csmarketcap"-->
      <!--           target="_blank">-->
      <!--          <svg height="24" width="24">-->
      <!--            <use xlink:href="/icons/sprite.svg#i-vk"></use>-->
      <!--          </svg>-->
      <!--        </a>-->
      <!--      </div>-->
      <!--      <span class="copyright show-md-min">-->
      <!--          {{ `${new Date().getFullYear()} © CSMARKETCAP.COM` }}-->
      <!--      </span>-->
    </div>
  </footer>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {useResize} from '~/composables/resize'
import Logo from '~/components/Header/Components/Logo.vue'

const {t} = useI18n()
const currW = useResize()
const route = useRoute()
const localePath = useLocalePath()
</script>


<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

.footer {
  width: 100%;
  border-top: 1px solid $darkLight;
  margin-top: auto;
  background: $black;

  :deep(.logo) {
    margin-right: 0;
    position: relative;
    bottom: 10px;
    @media screen and (max-width: 768px) {
      bottom: 0;
    }
  }

  .item-links {
    padding: 0;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    margin: 2.05rem 0 30px;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .link-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $light;

      &:hover {
        color: $lighten;
      }
    }
  }

  .messenger-link {
    transition: transform 0.3s ease;
    padding: 0;
    display: inline-flex;

    svg {
      border-radius: 50%;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 4.4rem auto;

    @media screen and (max-width: 2000px) and (min-width: 1500px) {
      &.comparison {
        margin-left: 370px;
        width: calc(100% - 400px);
      }
    }

    &.search-page {
      @media screen and (max-width: 2000px) and (min-width: 1115px) {
        margin-left: 37rem;
        width: unset;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .copyright {
      margin-top: auto;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgba(168, 182, 210, 0.8);

      &.show-md-min {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      @media screen and (max-width: 768px) {
        margin-top: 3.2rem;
      }
    }

    .lside {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        margin-bottom: 6px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .mta {
          margin-top: unset;
        }
      }

    }

    .rside {
      display: flex;
      gap: 56px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
</style>

