import NodeCache from 'node-cache';
import {getCookie, H3Event} from "h3";
// TODO: move to env
// const apiUrl = 'https://duke-possession-interest-uniform.trycloudflare.com/api/v2'

const cache = new NodeCache(); // Время жизни кэша будет задаваться динамически

export const useGraphQLRequest = () => {
    const requestGraph = async <T>(
        {
            baseUrl,
            query,
            variables = {},
            method = 'POST',
            cached = false,
            cacheTTL = 1000 * 60 * 60 * 24, // 24 hours
            headers = {},
            signal,
            timeout = 50000 // Таймаут по умолчанию 5 секунд
        }: {
            baseUrl?: string,
            query: string,
            variables?: Record<string, any>,
            method?: 'POST',
            cached?: boolean,
            cacheTTL?: number,
            headers?: Record<string, string>,
            signal?: AbortSignal | null,
            timeout?: number
        }): Promise<{ data: T, errors?: { message: string }[] }> => {

        let sessionHeader: any;

        try {
            if (process.server) {
                const event = useRequestEvent();
                const session = getCookie(event as H3Event, 'session');
                sessionHeader = session ? {Cookie: `session=${session}`} : {};
            }
        } catch (error) {
            sessionHeader = {};
        }
        // POD url
        try {
            if (process.server) {
                const config = useRuntimeConfig()
                const apiUrl = config?.public?.baseUrlV2
                baseUrl = (config?.public?.podUrl) + '/graphql';
            } else if (process.client) {
                baseUrl = '/api/graphql';
            }
        } catch (e) {
            console.error('Error getting baseUrl: ', e);
        }

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const fullUrl = baseUrl;
            const cacheKey = `${fullUrl}_${JSON.stringify({query, variables})}`;

            if (cached) {
                const cachedResponse = cache.get(cacheKey);
                if (cachedResponse) {
                    return cachedResponse as { data: T, errors?: { message: string }[] };
                }
            }
            const options: RequestInit = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    ...sessionHeader as any,
                    ...headers
                },
                credentials: 'include',
                body: JSON.stringify({query, variables}),
                signal: signal || controller.signal
            };

            const response = await fetch(fullUrl, options);

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const jsonData = await response.json();

            const result = {
                data: jsonData.data,
                errors: jsonData.errors
            };

            if (cached) {
                cache.set(cacheKey, result, cacheTTL);
            }

            return result;
        } catch (error) {
            console.error('Error on fetch: ', error);
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    };

    return {requestGraph};
};
