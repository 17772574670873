<template>
  <div ref="dd" :class="{ open: toggle }" class="cselect currency">
    <button class="cselect_top" @click="toggle = !toggle">
      <span class="item fw800">
        <span :class="`c-${currency}`"></span>
        <span>, {{ currency }}</span>
      </span>
      <i class="i-arrow"></i>
    </button>

    <div class="cselect_dropdown">
      <ul class="list cselect__list">
        <li v-for="(v, k) in currencies" :key="k">
          <button
              :key="k"
              :class="{ active: currency == k }"
              class="btn"
              type="button"
              @click="changeCurrency(k)"
          >
            <span :class="`c-${k}`" />
            <span>, {{ k.toUpperCase() }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {useClick} from '~/composables/click'
import {useStore} from '~/store'
import type {Currencies} from '~/types/Currencies'

const toggle = ref(false)

// use store
const store = useStore()

const currency = computed(() => store.currency)
const currencies = computed(() => store.currencies)

const changeCurrency = (curr: keyof Currencies) => {
  if (toggle.value) {
    store.setCurrency(curr)
    useCookie('currency').value = curr
  }

  toggle.value = false
}

const dd = ref<HTMLElement>()

useClick({el: dd, toggle})

</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

.currency {
  cursor: pointer;
  width: unset;
}

.cselect_dropdown .btn {
  justify-content: flex-start;
  padding: 1rem 1rem;
  width: 100%;
  font-size: 1.5rem;
}

.cselect_top {
  text-transform: uppercase;
}

.active {
  color: $green;
}
</style>
