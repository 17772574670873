type itemArgs = { name?: string, slug?: string, usedLocalePath?: boolean }
const exteriors = ['factory-new', 'minimal-wear', 'field-tested', 'well-worn', 'battle-scarred'];
const quality = ['stattrak', 'souvenir'];
const otherCategoryItems = ['agents', 'capsule', 'tools', 'collectibles', 'souvenir', 'crates', 'music-kit', 'sticker', 'graffiti', 'patch', 'case', 'collection', 'container', 'keys', 'pins', 'patch',]

export function getItemLink({name, slug, usedLocalePath = true}: itemArgs) {
    if (!name && !slug) {
        return '';
    }

    const localePath = useLocalePath();
    const newName = getModifiedString(getItemNameWithCategory(name));
    const exterior = exteriors.find(exterior => slug?.includes(exterior));
    if (exterior) {
        slug = slug?.replace(new RegExp(`-(?=${exterior})`), '/');
    }

    return usedLocalePath ? localePath(`/item/${slug || newName}`) : `/item/${slug || newName}`;
}

export function getItemLinkWithExterior({name, slug, usedLocalePath = true}: itemArgs) {
    if (!name && !slug) {
        return '';
    }

    const exteriorSlug = exteriors.find(exterior => slug?.includes(exterior));
    if (exteriorSlug) {
        slug = slug?.replace(new RegExp(`-(?=${exteriorSlug})`), '/');
    }

    const localePath = useLocalePath();
    const exterior = getModifiedString(getExterior(name));
    const newName = getModifiedString(getItemNameWithCategory(name));

    return usedLocalePath ? localePath(`/item/${slug || newName}${!slug && exterior ? `/${exterior}` : ''}`) : `/item/${slug || newName}${!slug && exterior ? `/${exterior}` : ''}`;

}

export function getItemLinkWithoutExtAndStatrack({name, slug, usedLocalePath = true}: itemArgs) {
    if (!name && !slug) {
        return '';
    }

    const localePath = useLocalePath();
    const newName = getModifiedString(getItemNameWithCategory(name));
    const exterior = exteriors.find(exterior => slug?.includes(exterior));
    if (exterior) {
        slug = slug?.replace(new RegExp(`^(${quality.join('|')})-(?=.+)`), '');
        slug = slug?.replace(new RegExp(`-${exterior}`), '');
    }

    return usedLocalePath ? localePath(`/item/${slug || newName}`) : `/item/${slug || newName}`;
}

export function getItemLinkWithoutExt({name, slug, usedLocalePath = true}: itemArgs) {
    if (!name && !slug) {
        return '';
    }

    const localePath = useLocalePath();
    const exterior = exteriors.find(exterior => slug?.includes(exterior));
    if (exterior) {
        slug = slug?.replace(new RegExp(`-${exterior}`), '');
    }

    return usedLocalePath ? localePath(`/item/${slug}`) : `/item/${slug}`;
}


export function getContainerLink({name, slug, category, usedLocalePath = true}: itemArgs & {
    category?: string
}) {
    if (!name && !slug) {
        return '';
    }
    if (category) {
        category = category.toLowerCase();
    }

    const exterior = exteriors.find(exterior => slug?.includes(exterior));
    if (exterior) {
        slug = slug?.replace(new RegExp(`-(?=${exterior})`), '/');
    }
    const localePath = useLocalePath();
    const newName = slug || getModifiedString(getItemNameWithCategory(name));

    let result;
    if (newName.includes('capsule') || newName.includes('case') || newName.includes('box') || newName.includes('pack') || newName.includes('collection') || newName.includes('storage') || newName.includes('pallet')) {
        result = usedLocalePath ? localePath(`/containers/${newName}`) : `/containers/${newName}`;
    } else if (category?.includes('capsule') || category?.includes('case') || category?.includes('box') || category?.includes('pack') || category?.includes('collection') || category?.includes('storage') || category?.includes('pallet')) {
        result = usedLocalePath ? localePath(`/containers/${newName}`) : `/containers/${newName}`;
    } else {
        result = getItemLinkWithExterior({name, slug, usedLocalePath});
    }
    return result;
}

export function getWeaponLink(name?: string, usedLocalePath: boolean = true) {
    if (!name) {
        return '';
    }

    const localePath = useLocalePath();
    const nameModified = getModifiedString(name);

    return usedLocalePath ? localePath(`/weapons/${nameModified}`) : `/weapons/${nameModified}`;
}

export function getAllWeaponsPageLink(name?: string, usedLocalePath: boolean = true) {
    if (!name) {
        return '';
    }

    const localePath = useLocalePath();
    const nameModified = getModifiedString(name);
    const includeOtherCategory = otherCategoryItems.find(i => nameModified.includes(i));

    return usedLocalePath ? localePath(`/weapons${includeOtherCategory ? '' : '/all'}/${nameModified}`) : `/weapons${includeOtherCategory ? '' : '/all'}/${nameModified}`;
}

export function getItemNameOnly(name?: string) {
    if (!name) {
        return '';
    }

    return name.replace(/.*\|\s*(.*?)\s*(?=\(|$).*/, '$1');
}

export function getWeaponOnly(name?: string) {
    if (!name) {
        return '';
    }

    return name.replace(/ \| .*/, '');
}

export function getCollection(name?: string) {
    if (!name) {
        return '';
    }
    if (name.toLowerCase().includes('case')) {
        const {t} = useI18n()
        return t('filters.weapon.Case');
    }
    return name.replace(/^(.*?) \|.*/, '$1').trim();
}

export function getItemNameWithCategory(name?: string) {
    if (!name) {
        return '';
    }

    return name.replace(/(?:^\s*|\s*\([^)]*\)\s*|\s*$)/g, '');
}

export function getExterior(name?: string) {
    if (!name) {
        return '';
    }

    const matches = name.match(/\(([^)]+)\)/g);
    return matches ? matches[matches.length - 1].slice(1, -1) : '';
}

export function getModifiedString(str?: string) {
    if (!str) {
        return '';
    }

    str = str.replace(/[^a-z\d]+/gi, '-');
    if (str.charAt(str.length - 1) == '-') {
        str = str.substr(0, str.length - 1);
    }
    if (str.charAt(0) == '-') {
        str = str.substr(1, str.length - 1);
    }
    return str.toLowerCase();
}
